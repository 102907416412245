
.privacy-title{
    background-color: #375c9e;
    display: flex;
    justify-content: center;
    height: 11vh;
    padding-top: 2vh;
    font-size: var(--step-3);
    font-weight: bold;
    font-style: normal;
    text-align: center;
    color: #FFFFFF;
}

.privacy-content{
    padding: 2%;
    font-size: var(--step-0);
}

.privacy-paragraph{
    padding-top: 3vh;
}

.privacy-bold{
    padding-top: 3vh;
    font-size: var(--step-0);
    font-weight: bold;
}