.contact-us{
    color: white;
    font-size: var(--step-1);
}
.cards-container {
    display: flex;
    justify-content: space-around;
    font-size: var(--step-1);
    padding: 1em;overflow: hidden;
}

.card1 {
    background-image: url("../../assets/flogo.webp");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left;
    border: 1px solid #ccc;
    border-radius: 18px;
    width: 45%;
    padding: 1em 2em 1em 0em;
    margin-bottom: 2em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: var(--primary);
    position: relative;
}


.card2 {
    border: 1px solid #ccc;
    border-radius: 18px;
    width: 45%;
    padding: 1em 2em 0 1em;
    margin-bottom: 2em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: var(--secondary)
}

.flogo {
    height: 100%;
    width: auto;
    position: absolute;
    padding-right: 20%;
}

.contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10%;
}

.contact-item-first{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 2%;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 12%;
}

.contact-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;

}

.contact-txt{
    padding-left: 5%;
    padding-right: 5%;
}

@media (max-width: 768px) {
    .cards-container {
        flex-direction: column;
        align-items: center;
    }

    .card1 {
        width: 100%;
        margin-bottom: 1em;
    }

    .card2 {
        width: 100%;
    }

}