.navbar {
    display: flex;
    justify-content: space-between;
    padding: 1rem 4rem 1em;
    background-color: var(--primary);
    position: fixed; /* This will make the navbar fixed to the top of the viewport */
    top: 0;
    width: 100%; /* Ensure the navbar spans the full width of the viewport */
    z-index: 1000; /* High z-index ensures the navbar is always on top of other content */
}


.navbar-brand {
    color: white;
    width: 3rem;
    height: auto;
}

.menu {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    z-index: 2;

}

.menu-item {
    color: white;
    position: relative;
    font-weight: bolder;
    padding: 0.5em 0.5em 10px;

}
.language-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #375c9e;
    border: none;
    border-radius: 20px;
    padding: var(--step--2) var(--step--2);
    cursor: pointer;
    color: white;
    font-weight: 600;
    transition: background-color 0.3s ease;

}
.no-display{
    display: none;
}

.language-button:hover {
    background-color: #2c4d84;
}

.language-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(55, 92, 158, 0.5);
}

/* Remove default styles from a tags */
a {
    text-decoration: none;
    color: inherit;
}

/* Style for Link components of react-router-dom */
a > .link {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
}

a > .link:hover {
    color: #fff
}

.menu-item:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0.2rem;
    border-radius: 50px;
    background-color: #fff;
    transition: width 0.3s;
}

.menu-item:hover:after,
.menu-item.active:after {
    width: 100%;
}

.hamburger {
    display: none; /* Hides by default (for desktop) */
}


/* Mobile styles */
@media (max-width: 768px) {
    .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        background-color: rgba(16, 55, 123, 0.8);
        transform: translateY(-100%);
        transition: transform 0.3s ease;
    }

    .menu.active {
        transform: translateY(0);
    }

    .menu-item {
        font-size: 1.5rem;
    }

    .hamburger {
        display: block; /* Shows in mobile */
    }

    .navbar{
        background: rgba(0,0,0,0);
        align-items: center;
        padding: 2rem 2rem 1em;

    }
    .language-button{
        display: none;
    }
    .no-display{
        display: block;
    }
    .navbar-brand {
        width: 2rem;
    }
}
