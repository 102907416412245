.footer{
    width: 100%;
    background: var(--neutral-light);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    font-size: var(--step--2);
}

.footer > div{
    padding-right: 2em;
    padding-left: 2em;
}


@media screen and (max-width: 768px){
    .footer{
        flex-direction: column;
        gap: 1em;
        align-items: center;
    }
    .footer > div{
        padding: 0;
    }
}