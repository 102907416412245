
.muscular{
    width: 100%;
    height: auto;
    filter: brightness(50%);
}

.image-container{
    position: relative;
}

.title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--step-6);
    font-weight: bold;
    font-style: normal;
    text-align: center;
    color: #FFFFFF;
}

.centered {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.AboutContainer{
    overflow: hidden;
    padding: 3em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background);
    background-image: url("../../assets/backlogo.webp");
    background-size: auto 70%;
    background-repeat: no-repeat;
    background-position: center;
}

.backlogo{
    height: 70%;
    width: auto;
    position: absolute;
    filter: brightness(50%);
}

.AboutUs {
    padding-bottom: 20px;
    font-size: var(--step-4);
    font-weight: bold;
    font-style: normal;
}

.parageaph {
    font-size: var(--step-0);
    font-weight: normal;
    width: 90%;
}

.AppContainer{
    background-color: var(--secondary-dark);
    display: flex;
    justify-content: center;
    align-items: center;

}

.App-title{
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: var(--step-4);
    font-weight: bold;
    font-style: normal;
    text-align: center;
    color: white;
}

.PhoneImg {
    display: flex;
    width: 75vw;
}

.download-bottons{
    background-color: #E73C65;
    display: flex;
    justify-content: center;
}

.download-link{
    width: 30%;
    padding: 1%;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
}

.downloadImg {
    padding: 1%;
    padding-bottom: 50px;
}


@media (max-width: 800px) {
    .title{
        font-size: var(--step-2);
    }

    .download-bottons{
        background-color: #E73C65;
        display: flex;
        justify-content: center;
    }

    .download-link{
        display: flex;
        justify-content: center;
        width: 45%;
        padding: 1%;
        padding-bottom: 50px;
    }

    .downloadImg {
        width: 100%;
        padding: 1%;
        padding-bottom: 50px;
    }

    .PhoneImg {
        width: 100vw;
        padding-bottom: 20px;
    }
    .backlogo {
        height: 30%;
    }

    .AboutUs {
        padding-bottom: 20px;
        font-size: var(--step-2);
        font-weight: bold;
        font-style: normal;
    }

    .parageaph {
        font-size: var(--step--1);
        font-weight: normal;
        width: 100%;
    }

}